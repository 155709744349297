import "./App.css";
import githubIcon from "./github-alt.svg";
import linkedinIcon from "./linkedin-in.svg";
import profile from "./profile.png";
import twitterIcon from "./twitter.svg";

import { useEffect, memo, useState } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { config } from "./particlesConfig";

const nameTitle = "Robert F. O'Connor";
const subtitle = "Senior Developer for Web & Mobile";

const socials = [
  {
    name: "Github",
    link: "https://github.com/RobertFOConnor",
    icon: githubIcon,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/robert-o-connor-72b3416b",
    icon: linkedinIcon,
  },
  {
    name: "Twitter",
    link: "https://twitter.com/YellowbyteGames",
    icon: twitterIcon,
  },
];

const IconButton = ({ name, link, icon, index, isMobile }) => (
  <div onClick={() => window.open(link, "_blank")}>
    <img
      className={isMobile ? "socialIconMobile" : "socialIcon"}
      src={icon}
      style={index == 0 ? { marginLeft: 0 } : {}}
    />
  </div>
);

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const ParticleBG = memo(() => {
  const particlesInit = async (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  return (
    <div className="background">
      <Particles id="tsparticles" init={particlesInit} options={config} />
    </div>
  );
});

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [title, setTitle] = useState(nameTitle);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    if (isMobile) return;
    let iterations = 0;
    const interval = setInterval(() => {
      setTitle((title) =>
        title
          .split("")
          .map((letter, index) =>
            letter === " "
              ? " "
              : index >= iterations
              ? letters[Math.floor(Math.random() * 26)]
              : nameTitle.charAt(index)
          )
          .join()
          .replaceAll(",", "")
      );
      if (iterations >= nameTitle.length) {
        clearInterval(interval);
      } else {
        iterations += 1 / 3;
      }
    }, 30);
  }, []);

  if (isMobile) {
    return (
      <div className="AppMobile">
        <ParticleBG />
        <img
          src={"https://avatars.githubusercontent.com/u/10901609?v=4"}
          className="ProfilePicMobile"
          alt="logo"
        />
        <div className="title" style={{ fontSize: 34, marginTop: 12 }}>
          Hi, I'm
        </div>
        <div
          className="titleAccent"
          style={{ width: "100%", maxLines: 1, fontSize: 34 }}
        >
          {title}
        </div>
        <div className="subtitleMobile">{subtitle}</div>
        <div className="socialsRow">
          {socials.map((social, index) => (
            <IconButton {...social} index={index} isMobile={true} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <ParticleBG />
      {/* <div className="topMenu"></div> */}
      <div className="leftSection">
        <div className="title">Hi, I'm</div>
        <span className="titleAccent">{title}</span>
        <div className="subtitle">{subtitle}</div>
        <div className="socialsRow">
          {socials.map((social, index) => (
            <IconButton {...social} index={index} />
          ))}
        </div>
      </div>
      <div className="rightSection">
        <img src={profile} className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default App;
